.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: var(--appMaxWidth);
  min-height: 100dvh;
  margin: 0 auto;
  box-sizing: border-box;
}

.wrapper :global(.adm-form .adm-form-footer) {
  padding: 16px;
}

.wrapper :global(.adm-list-card) {
  margin: 12px 16px;
}
