.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: var(--appMaxWidth);
  min-height: 100%;
  margin: 0 auto var(--gap-4xl) auto;
  padding: 0;
}

.chatWrapper {
  min-height: auto;
}

.profileWrapper {
  background: var(--background-color-old);
}
