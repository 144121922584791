body {
  margin: 0;
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

:root {
  /* color-scheme */
  --primary-color-old: #f2c94c;
  --primary-color: #402c76;
  --second-color: #9f83d7;
  --primary-color-temporarily: #f9de55;
  --linear-gradient: linear-gradient(247deg, #402c76 -268.71%, #9f83d7 184.95%);
  --linear-gradient-widget: linear-gradient(226deg, #402c76 -72.79%, #9f83d7 111.65%);
  --success-mark: #00b578;
  --background-color-old: #f8f8f8;
  --background-color: #402c76;
  --shadow-color: rgba(51, 51, 51, 0.2);
  --shadow-header-color: rgba(51, 51, 51, 0.05);
  --character-primary-85: rgba(0, 0, 0, 0.85);
  --linear-button-start: #4c4c4c;
  --linear-button-end: #454545;
  --dark-color: #333333;
  --black-color: #000000;
  --light-color: #666666;
  --gray-1: #eeeeee;
  --gray-2: #cccccc;
  --gray-3: #999999;
  --gray-4: #d1d1d1;
  --gray-5: #f2f2f7;
  --white-color: #ffffff;
  --link-underline: #4b6b99;
  --red-color: #ff3141;
  --orange-color: #ff6430;
  --light-orange-color: #ffa850;
  --upload-button-color: #ffd3a7;
  --green-color: #69cd94;
  --green-color-light: #f0faf4;
  --agreements-link-color: #203e2c;
  --progress-bar-color: #e5e5e5;
  --link-color: #3578f6;

  /* padding, margin */
  --gap-2xs-neg: -4px;
  --gap-3xs: 2px;
  --gap-2xs: 4px;
  --gap-xs: 8px;
  --gap-2s: 10px;
  --gap-s: 12px;
  --gap-2m: 15px;
  --gap-m: 16px;
  --gap-l: 20px;
  --gap-xl: 24px;
  --gap-2xl: 32px;
  --gap-3xl: 40px;
  --gap-4xl: 50px;
  --gap-5xl: 60px;
  --gap-6xl: 64px;
  --gap-7xl: 72px;
  --gap-8xl: 96px;
  --gap-9xl: 128px;
  --gap-10xl: 135px;
  --gap-11xl: 180px;

  /* font-weight */
  --regular: 400;
  --medium: 500;
  --semi-bold: 600;
  --bold: 700;
  --extra-bold: 800;

  /* font-size */
  --font-xs: 10px;
  --font-s: 12px;
  --font-s-2: 13px;
  --font-xm: 14px;
  --font-m-1: 15px;
  --font-m: 16px;
  --font-l-2: 17px;
  --font-l-1: 18px;
  --font-l: 20px;
  --font-2l: 21px;
  --font-3l: 22px;
  --font-xl: 24px;
  --font-2xl: 32px;
  --font-4xl: 48px;
  --font-5xl: 56px;
  --font-5xl-2: 62px;
  --font-6xl: 76px;

  /* font color */
  --color-default: #333333;

  /* line-height */
  --line-m: 16px;
  --line-l: 21px;
  --line-xl: 24px;
  --line-2xl: 34px;
  --line-3xl: 40px;
  --line-3xl-2: 48px;
  --line-4xl: 59px;
  --line-5xl: 64px;

  /* letter-spacing */
  --neg-let-spacing-s: -2px;

  /* border-radius */
  --border-radius-xs: 2px;
  --border-radius-s: 4px;
  --border-radius-m: 8px;
  --border-radius-2m: 10px;
  --border-radius-l: 12px;
  --border-radius-xl: 16px;
  --border-radius-xxl: 20px;
  --border-radius-3xl: 24px;
  --border-radius-circle: 50%;
  --border-radius-pill: 99px;

  /* height */
  --tag-height: 21px;
  --user-icon-height: 34px;
  --close-icon-height: 16px;
  --step-icon-height: 18px;
  --button-app-height: 41px;
  --button-height: 49px;
  --header-height: 44px;
  --footer-height: 48px;
  --widget-height: 164px;
  --partners-icon-height: 52px;

  /* width */
  --user-icon-width: 34px;
  --close-icon-width: 16px;
  --step-icon-width: 18px;
  --dialog-width: 280px;
  --appMaxWidth: 460px;
  --input-term-width: 40px;
  --input-amount-width: 95px;
  --partners-icon-width: 52px;
}

html,
body,
#root {
  width: 100%;
  height: 100%;
}

body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
  margin: 0;
}

ul,
ol {
  padding: 0;
  margin: 0;
  list-style-type: none;
}

a {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
}

input {
  font-family: Montserrat;
  font-size: var(--font-l-2);
  font-weight: var(--regular);
  line-height: var(--line-xl);
}

div[class^='adm-list-header'],
div[class^='adm-list-item'],
a[class^='adm-list-item'] {
  padding-left: 0;
  padding-right: 0;
}
