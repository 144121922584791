.header {
  width: 100vw;
  max-width: var(--appMaxWidth);
  box-shadow: 0 3px 10px 0 var(--shadow-header-color);
}

.navbar {
  --height: var(--header-height);
  --border-bottom: 1px var(--border-color) solid;
  font-size: var(--font-l-1);
  font-weight: var(--semi-bold);
  line-height: var(--line-xl);
  color: var(--dark-color);
}

.navbarLinkHas :global(.adm-nav-bar-title) {
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.title {
  margin: auto;
  font-weight: var(--medium);
}

.downloadLink {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (max-width: 360px) {
  .titleSmall {
    font-size: var(--font-xm);
  }
}
