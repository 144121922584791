.footer {
  position: fixed;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
  width: 100%;
  max-width: var(--appMaxWidth);
  height: var(--footer-height);
  background-color: var(--white-color);
  z-index: 4;
}

.tabBar .activeItem {
  font-weight: bolder;
}

.tabBarItem {
  color: var(--light-color);
  font-feature-settings: 'clig' off, 'liga' off;
  font-size: var(--font-xs);
}

.tabBarItem :global(.adm-tab-bar-item-title) {
  font-weight: var(--regular);
}

.tabIconActive {
  color: var(--primary-color);
}
