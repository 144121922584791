.wrapper {
  position: fixed;
  top: 40px;
  left: 50%;
  width: 350px;
  transform: translateX(-50%);
}

.alert {
  animation: show 0.5s ease forwards;
}

@keyframes show {
  from {
    transform: translateY(-100%);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}
